import { useEffect, useRef, useState } from 'react'
import { useRedirectContext } from '../context/redirect.context'
import { useUserContext } from '../context/user.context'
import useClickOutside from '../hooks/useClickOutside'

const Redirect = () => {
    const { 
        signOut,
        register,
        emailRegister,
        setEmailRegister,
        passwordRegister,
        setPasswordRegister,
        passwordRepeatRegister,
        setPasswordRepeatRegister,
        disabledButtonRegister,
        isRegistered,
    } = useUserContext()
    const { 
        isLoadingRedirectData,
        isRedirectUpdated,
        hasErrorRedirectData,
        redirectData, 
        getRedirectData,
        url,
        putUrl,
        setUrl,
        setPutUrl,
        putRedirectUrl,
        postRedirectUrl,
        deleteRedirectUrl,
    } = useRedirectContext()
    const [openUserModal, setOpenUserModal] = useState(false)
    const [openRedirectModal, setOpenRedirectModal] = useState(false)
    const [activeRedirect, setActiveRedirect] = useState(undefined)

    const modalUserRef = useRef(null)
    const modalRedirectRef = useRef(null)
    useClickOutside(modalUserRef, setOpenUserModal)
    useClickOutside(modalRedirectRef, setOpenRedirectModal)

    const handleOpenRedirect = (redirect) => {
        setPutUrl(redirect.url)
        setActiveRedirect(redirect)
        setOpenRedirectModal(!openRedirectModal)
    }

    const handleDeleteRedirect = (e, redirect) => {
        if (window.confirm(`Are you sure you want to delete ${redirect.url} ?`)) {
            deleteRedirectUrl(e, redirect._id)
        }
    }

    useEffect(() => {
        getRedirectData()
    }, [])

    useEffect(() => {
        if (isRegistered) {
            setOpenUserModal(false)
            setEmailRegister('')
            setPasswordRegister('')
            setPasswordRepeatRegister('')
        }
    }, [isRegistered])

    return (
        <main className="bg-blue-main min-h-screen flex items-center justify-center">
            <span className={`fixed z-50 top-8 bg-green-500 px-16 py-2 rounded-full text-lg transition-opacity ${isRedirectUpdated ? 'opacity-100' : 'opacity-0'}`}>{isRedirectUpdated}</span>
            <span className={`fixed z-50 top-8 bg-green-500 px-16 py-2 rounded-full text-lg transition-opacity ${isRegistered ? 'opacity-100' : 'opacity-0'}`}>Successfully Registered</span>
            
            <button className="group fixed top-8 right-8 z-50" onClick={() => setOpenUserModal(!openUserModal)}>
                <svg className="h-10 w-10 fill-white group-hover:fill-primary transition-colors" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path></svg>
            </button>

            <div className={`fixed z-30 inset-0 bg-black transition-colors ${openUserModal || openRedirectModal ? 'bg-opacity-60' : 'bg-opacity-0 pointer-events-none'}`} />

            <div className="flex flex-col gap-8 w-full p-2 md:p-4">
                <h1 className="text-6xl text-white font-bold text-center tracking-wider">Redirect</h1>
                {isLoadingRedirectData ? (
                    <section className="flex items-center justify-center w-full">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <p className="text-white text-center italic font-semibold">Loading...</p>
                    </section>
                ) : hasErrorRedirectData ? (
                    <p className="text-white text-center italic font-semibold">Something went wrong.</p>
                ) : (
                    <>
                        <section className="flex flex-col gap-4 w-full max-w-3xl mx-auto bg-white p-4 md:p-8 rounded-xl text-center">
                            <h2 className="text-2xl font-bold">Create Redirect URL</h2>
                            <form onSubmit={postRedirectUrl} className="flex flex-col gap-4 w-full max-w-lg mx-auto">
                                <span className="flex flex-col items-start">
                                    <label htmlFor="url" className="ml-6 italic text-sm font-semibold">
                                        Redirect Url
                                    </label>
                                    <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="url" name="url" type="url" placeholder="https://redirect.url" value={url} onChange={(e) => setUrl(e.target.value)} />
                                </span>
                                <button className="transition-colors bg-primary text-white hover:bg-primary-hover disabled:bg-gray-400 px-4 py-2 rounded-full uppercase font-bold" type="submit" disabled={!url}>Submit</button>
                            </form>
                        </section>

                        <section className="flex flex-col gap-4 w-full max-w-3xl mx-auto bg-white p-2 md:p-8 rounded-xl text-center">
                            <h2 className="text-2xl font-bold">Redirect URL's</h2>
                            {redirectData?.length > 0 ? (
                                <table className='table-auto'>
                                    <thead>
                                        <tr>
                                            <th >ID</th>
                                            <th >URL</th>
                                            <th >Updated By</th>
                                            <th >Updated At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {redirectData.map(redirect => (
                                            <tr key={redirect._id}>
                                                <td className=''>{redirect._id}</td>
                                                <td><a href={redirect.url} target="_blank" className="hover:underline hover:text-primary">{redirect.url}</a></td>
                                                <td>{redirect.updatedBy}</td>
                                                <td><time dateTime={redirectData?.updatedAt}>{redirect.updatedAt ? new Date(redirect.updatedAt).toLocaleString(): ''}</time></td>
                                                <td onClick={() => handleOpenRedirect(redirect)} className="group hover:cursor-pointer">
                                                    <svg className="fill-black group-hover:fill-primary w-6 h-6" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                                                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                                                    </svg>
                                                </td>
                                                <td onClick={(e) => handleDeleteRedirect(e, redirect)} className="group hover:cursor-pointer">
                                                    <svg className="fill-black group-hover:fill-primary w-6 h-6" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path>
                                                    </svg>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No redirect url's set up.</p>
                            )}
                        </section>
                    </>
                )}

                {openUserModal && (
                    <div className="fixed z-40 inset-0 flex items-center justify-center p-4">
                        <section ref={modalUserRef} className="flex flex-col gap-4 w-full max-w-3xl mx-auto bg-white p-8 rounded-xl text-center shadow-xl">
                            <h2 className="text-2xl font-bold">Create User</h2>
                            <form onSubmit={register} className="flex flex-col gap-4 w-full max-w-lg mx-auto">
                                <span className="flex flex-col items-start">
                                    <label htmlFor="email" className="ml-6 italic text-sm font-semibold">
                                        Email
                                    </label>
                                    <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="email" name="email" type="email" placeholder="user@gowin.city" value={emailRegister} onChange={(e) => setEmailRegister(e.target.value)} />
                                </span>
                                <span className="flex flex-col items-start">
                                    <label htmlFor="password" className="ml-6 italic text-sm font-semibold">
                                        Password
                                    </label>
                                    <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="password" name="password" type="password" placeholder="Password" value={passwordRegister} onChange={(e) => setPasswordRegister(e.target.value)} />
                                </span>
                                <span className="flex flex-col items-start">
                                    <label htmlFor="passwordRepeat" className="ml-6 italic text-sm font-semibold">
                                        Repeat Password
                                    </label>
                                    <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="passwordRepeat" name="passwordRepeat" type="password" placeholder="Repeat Password" value={passwordRepeatRegister} onChange={(e) => setPasswordRepeatRegister(e.target.value)} />
                                </span>
                                <button className="transition-colors bg-primary text-white hover:bg-primary-hover disabled:bg-gray-400 px-4 py-2 rounded-full uppercase font-bold" type="submit" disabled={disabledButtonRegister}>Submit</button>
                            </form>
                            <button className="transition-colors uppercase text-primary hover:text-primary-hover px-4 py-2 rounded font-semibold w-full max-w-lg mx-auto" onClick={signOut}>Sign Out</button>
                        </section>
                    </div>
                )}

                {openRedirectModal && activeRedirect && (
                    <div className="fixed z-40 inset-0 flex items-center justify-center p-4">
                        <section ref={modalRedirectRef} className="flex flex-col gap-4 w-full max-w-3xl mx-auto bg-white p-8 rounded-xl text-center shadow-xl">
                            <h2 className="text-2xl font-bold">Change Redirect URL</h2>
                            <form onSubmit={(e) => putRedirectUrl(e, activeRedirect._id)} className="flex flex-col gap-4 w-full max-w-lg mx-auto">
                                <span className="flex flex-col items-start">
                                    <label htmlFor="url" className="ml-6 italic text-sm font-semibold">
                                        Redirect Url
                                    </label>
                                    <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="url" name="url" type="url" placeholder="https://redirect.url" value={putUrl} onChange={(e) => setPutUrl(e.target.value)} />
                                </span>
                                <button className="transition-colors bg-primary text-white hover:bg-primary-hover disabled:bg-gray-400 px-4 py-2 rounded-full uppercase font-bold" type="submit" disabled={!putUrl}>Submit</button>
                            </form>
                        </section>
                    </div>
                )}
            </div>
        </main>
    )
}

export default Redirect