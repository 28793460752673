import { createContext, useState, useContext, useEffect } from "react";
import axios from '../utils/axios'
import { useUserContext } from "./user.context";

const RedirectContext = createContext(undefined);

const useRedirectContext = () => useContext(RedirectContext);

const RedirectProvider = ({ children }) => {
    const { user } = useUserContext()
    const [isLoadingRedirectData, setIsLoadingRedirectData] = useState(false)
    const [hasRedirectData, setErrorRedirectData] = useState(false)
    const [isRedirectUpdated, setIsRedirectUpdated] = useState('')
    const [redirectData, setRedirectData] = useState()
    const [url, setUrl] = useState('')
    const [putUrl, setPutUrl] = useState('')
    
    const getRedirectData = async () => {
        if (isLoadingRedirectData) return;
        setIsLoadingRedirectData(true)
        try {
            const options = {
                headers: {
                    'Authorization': `TT ${user?.token}`
                },
            };
        
            const { data } = await axios.get('/api/redirect/status', options);
            setRedirectData(data)
        } catch (error) {
            setErrorRedirectData(true);
            alert(error.response.data);
        } finally {
            setIsLoadingRedirectData(false)
        };
    };

    const postRedirectUrl = async (e) => {
        e.preventDefault();
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `TT ${user?.token}`
            },
        };
        setIsLoadingRedirectData(true)
        try {
            const { data } = await axios.post('/api/redirect', { url }, options);
            // alert(`Redirect URL updated to: ${data.url}`);
            setUrl('')
            getRedirectData()
            setIsRedirectUpdated(`Redirect URL: ${data.url} added`)
        } catch (error) {
            setErrorRedirectData(true);
            alert(error.response.data);
        } finally {
            setIsLoadingRedirectData(false)
        };
    };

    const putRedirectUrl = async (e, id) => {
        e.preventDefault();
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `TT ${user?.token}`
            },
        };
        setIsLoadingRedirectData(true)
        try {
            const { data } = await axios.put(`/api/redirect/${id}`, { url: putUrl }, options);
            // alert(`Redirect URL updated to: ${data.url}`);
            setPutUrl('')
            getRedirectData()
            setIsRedirectUpdated(`Redirect URL updated to: ${data.url}`)
        } catch (error) {
            setErrorRedirectData(true);
            alert(error.response.data);
        } finally {
            setIsLoadingRedirectData(false)
        };
    };

    const deleteRedirectUrl = async (e, id) => {
        e.preventDefault();
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `TT ${user?.token}`
            },
        };
        setIsLoadingRedirectData(true)
        try {
            await axios.delete(`/api/redirect/${id}`, options);
            // alert(`Redirect URL updated to: ${data.url}`);
            setPutUrl('')
            getRedirectData()
            setIsRedirectUpdated(`Redirect URL removed!`)
        } catch (error) {
            setErrorRedirectData(true);
            alert(error.response.data);
        } finally {
            setIsLoadingRedirectData(false)
        };
    };

    useEffect(() => {
        if (isRedirectUpdated) {
            setTimeout(() => {
                setIsRedirectUpdated('')
            }, 5000)
        }
    }, [isRedirectUpdated])

    return (
        <RedirectContext.Provider
            value={{
                isLoadingRedirectData,
                isRedirectUpdated,
                hasRedirectData,
                redirectData,
                url,
                putUrl,
                setUrl,
                setPutUrl,
                getRedirectData,
                postRedirectUrl,
                putRedirectUrl,
                deleteRedirectUrl,
            }}
        >
            { children }
        </RedirectContext.Provider>
    )
}

export { RedirectContext, RedirectProvider, useRedirectContext }