import React from 'react'
import { useUserContext } from '../context/user.context'

const Auth = () => {
    const { email, setEmail, password, setPassword, signIn, disabledButton } = useUserContext()

    return (
        <main className="bg-blue-main min-h-screen flex items-center justify-center">
            <div className="flex flex-col gap-8 w-full p-4">
                <h1 className="text-6xl text-white font-bold text-center tracking-wider">Redirect</h1>
                <section className="flex flex-col gap-4 w-full max-w-3xl mx-auto bg-white p-8 rounded-xl text-center">
                    <h1 className="text-2xl font-bold">Sign In</h1>
                    <form onSubmit={signIn} className="flex flex-col gap-4 w-full max-w-lg mx-auto">
                        <span className="flex flex-col items-start">
                            <label htmlFor="email" className="ml-6 italic text-sm font-semibold">
                                Email
                            </label>
                            <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="email" name="email" type="email" placeholder="user@gowin.city" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </span>
                        <span className="flex flex-col items-start">
                            <label htmlFor="password" className="ml-6 italic text-sm font-semibold">
                                Password
                            </label>
                            <input className="border border-blue-main px-6 py-2 rounded-full w-full" id="password" name="password" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </span>
                        <button className="transition-colors bg-primary hover:bg-primary-hover disabled:bg-gray-400 text-white px-4 py-2 rounded-full uppercase font-bold" type="submit" disabled={disabledButton}>Sign In</button>
                    </form>
                </section>
            </div>
        </main>
    )
}

export default Auth