import { createContext, useState, useContext, useEffect } from "react";
import axios from '../utils/axios'

const UserContext = createContext(undefined);

const useUserContext = () => useContext(UserContext);

const UserProvider = ({ children }) => {
    const [isLoadingUser, setIsLoadingUser] = useState(false)
    const [hasErrorUser, setErrorUser] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailRegister, setEmailRegister] = useState('')
    const [passwordRegister, setPasswordRegister] = useState('')
    const [passwordRepeatRegister, setPasswordRepeatRegister] = useState('')
    const [isRegistered, setIsRegistered] = useState(false)
    const [user, setUser] = useState()

    const disabledButton = !email || !password;
    const disabledButtonRegister = !emailRegister || !passwordRegister || !passwordRepeatRegister || passwordRegister !== passwordRepeatRegister
    
    const signIn = async (e) => {
        e.preventDefault();
        
        setIsLoadingUser(true)
        try {
            const { data } = await axios.post('/api/auth/signIn', {
                email,
                password
            });
            localStorage.setItem('user', JSON.stringify(data))
            setUser(data)
        } catch (error) {
            setErrorUser(true)
            alert(error.response.data);
        } finally {
            setIsLoadingUser(false)
        }
    };

    const signOut = () => {
        setUser('')
        setEmail('')
        setPassword('')
        localStorage.removeItem('user')
    }

    const register = async (e) => {
        e.preventDefault();
        
        setIsLoadingUser(true)
        try {
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `TT ${user?.token}`
                },
            };
            const { data } = await axios.post('/api/auth/register', {
                email: emailRegister,
                password: passwordRegister
            }, options);
            
            setIsRegistered(data.success)
        } catch (error) {
            setErrorUser(true)
            alert('Something went wrong, please try again.');
        } finally {
            setIsLoadingUser(false)
        }
    }

    useEffect(() => {
        if (isRegistered) {
            setTimeout(() => {
                setIsRegistered(false)
            }, 5000)
        }
    }, [isRegistered])

    return (
        <UserContext.Provider
            value={{
                isLoadingUser,
                hasErrorUser,
                user,
                setUser,
                signIn,
                signOut,
                email,
                setEmail,
                password,
                setPassword,
                disabledButton,
                register,
                emailRegister,
                setEmailRegister,
                passwordRegister,
                setPasswordRegister,
                passwordRepeatRegister,
                setPasswordRepeatRegister,
                disabledButtonRegister,
                isRegistered,
            }}
        >
            { children }
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider, useUserContext }