import { useUserContext } from './context/user.context';
import Auth from './components/Auth';
import Redirect from './components/Redirect';
import { useEffect } from 'react';

function App() {
  const { user, setUser } = useUserContext()

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user')
    if (loggedInUser && !user) setUser(JSON.parse(loggedInUser))
  }, [user])

  if (!user) {
    return <Auth />
  }

  return <Redirect />;
}

export default App;
